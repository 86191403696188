import { defineStore } from 'pinia';
import { Terminals } from '@/api/terminals.js';

export const terminalsStore = defineStore('storeTerminals', {
  state: () => ({
    allTerminalsFiltered: [],
    allTerminals: [],
    allCommands: [],
    allTerminalsMax: 0,
    terminalItem: null,
    terminalsList: [],
    filter: {
      integratorFilterModel: '',
      companyFilterModel: [],
      statusValue: null,
      lbsValue: null
    },
    count: 0,
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async postAllTerminals({
                             from = 0,
                             count = 100,
                             dealer,
                             companies = [],
                             field,
                             fields,
                             forceCall = false
                           }, pagination) {
      try {
        if (!this.allTerminals.length || pagination || forceCall) {
          const formData = {
            from,
            count,
            companies,
            forceCall,
            field,
            fields
          };
          if (this.filter.integratorFilterModel) {
            formData.dealer = { id: this.filter.integratorFilterModel };
          }
          if (this.filter.companyFilterModel.length) {
            formData.companies = this.filter.companyFilterModel.map((comp) => ({ id: comp }));
          }
          let conditions = [];
          if (this.filter.statusValue) {
            conditions.push({
              field: 'is_blocked',
              value: this.filter.statusValue.value
            });
          }
          if (this.filter.lbsValue) {
            conditions.push({
              field: 'use_lbs_for_coords',
              value: this.filter.lbsValue.value
            });
          }
          if (conditions.length) {
            formData.conditions = conditions;
          }
          if (this.sortDesc !== '') {
            formData.sortDesc = this.sortDesc;
            formData.sortField = this.sortField;
          }

          const response = await Terminals.postAllTerminals(formData);
          this.allTerminals = response.list;
          this.allTerminalsFiltered = response.list;
          this.allTerminalsMax = response.max;
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    postAllCommands({ from = 0, count = 100, conditions = [] }) {
      Terminals.postAllCommands({ from, count, conditions }).then((res) => {
        this.allCommands = res.list;
      });
    },
    deleteTerminals(id) {
      Terminals.deleteTerminals(id).then(() => {
        this.allTerminalsFiltered.forEach((val, key) => {
          if (val.id === id) {
            this.allTerminalsFiltered.splice(key, 1);
            this.allTerminalsMax -= 1;
          }
        });
      });
    },
    async updateTerminals(data) {
      try {
        const response = await Terminals.updateTerminals(data);
        this.allTerminalsFiltered.map((val, key) => {
          if (response.id === val.id) {
            response.company_name = data.company_name;
            this.allTerminalsFiltered.splice(key, 1, response);
          }
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async createTerminals(data) {
      try {
        const response = await Terminals.createTerminals(data);
        response.forEach((val) => {
          this.allTerminalsFiltered.push(val);
          this.allTerminalsMax += 1;
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    getTerminalId(id) {
      if (!this.terminalItem) {
        Terminals.getTerminalId(id).then((res) => {
          this.terminalItem = res;
        });
      }
    },
    async searchTerminals(data) {
      try {
        let conditions = [];

        if ('is_blocked' in data) {
          if (data.is_blocked === false || data.is_blocked === true) {
            conditions.push({ field: 'is_blocked', value: !data.is_blocked });
          }
          if (data.use_lbs_for_coords === false || data.use_lbs_for_coords === true) {
            conditions.push({
              field: 'use_lbs_for_coords',
              value: data.use_lbs_for_coords
            });
          }
        }

        const response = await Terminals.postAllTerminals({
          value: data.value,
          companies: data.companies,
          conditions
        });
        this.allTerminals = response.list;
        this.allTerminalsFiltered = response.list;
        this.allTerminalsMax = response.max;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async clearTerminalsFilters() {
      this.filter.companyFilterModel = [];
      this.filter.integratorFilterModel = '';
      this.filter.statusValue = null;
      this.filter.lbsValue = null;
      const clearData = {
        companies: [],
        count: 100,
        field: '',
        fields: [],
        from: 0,
        model: 'terminals',
        value: '',
        conditions: []
      };
      try {
        await this.postAllTerminals(clearData, true);
      } catch (error) {
        console.error('Error during clearing terminals:', error);
      }
    },

    filterTerminals(data) {
      this.allTerminalsFiltered = this.allTerminals.filter((val) => {
        let filteredItem = val;
        const type = 'imei';
        const value = data.value || '';

        if (data.status !== undefined && data.status !== '') {
          if (val.is_blocked !== data.status) {
            filteredItem = null;
          }
        }

        if (data.lbs !== undefined && data.lbs !== '') {
          if (val.use_lbs_for_coords !== data.lbs) {
            filteredItem = null;
          }
        }

        if (data.company) {
          if (val.company_name !== data.company.name) {
            filteredItem = null;
          }
        }

        if (filteredItem) {
          return (
            filteredItem[type] &&
            String(filteredItem[type])
              .toLowerCase()
              .includes(String(value).toLowerCase())
          );
        }
      });
    },
    async getTerminalsList() {
      const data = await Terminals.getAllTerminals();

      this.terminalsList = data.list;
    },

    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE_TERMINAL(id);

      const data = await Terminals.getTerminalId(id);

      operation === 'POST'
        ? this.ADD_TERMINAL(data)
        : this.UPDATE_TERMINAL(data);
    },
    ADD_TERMINAL(terminal) {
      if (!this.allTerminalsFiltered.some((x) => x.id === terminal.id)) {
        this.allTerminals = this.allTerminalsFiltered = [
          ...this.allTerminalsFiltered,
          terminal
        ];
      }
    },
    UPDATE_TERMINAL(terminal) {
      const idx = this.allTerminalsFiltered.findIndex(
        (elem) => elem.id === terminal.id
      );

      if (idx === -1) return;

      this.allTerminals = this.allTerminalsFiltered = [
        ...this.allTerminalsFiltered.slice(0, idx),
        terminal,
        ...this.allTerminalsFiltered.slice(idx + 1)
      ];
    },
    DELETE_TERMINAL(id) {
      this.allTerminals = this.allTerminalsFiltered =
        this.allTerminalsFiltered.filter((item) => item.id !== id);
    },
    updateSortedTerminals(payload) {
      this.allTerminalsFiltered = payload.list;
    }
  },

  getters: {
    getAllTerminals: (state) => state.allTerminalsFiltered,
    terminalsListGetter: (state) => state.terminalsList
  }
});
