import { defineStore } from 'pinia';
import { CommandsTemplates } from '@/api/commandsTemplates.js';

export const commandsTemplatesStore = defineStore('storeCommandsTemplates', {
  state: () => ({
    allCommandsTemplatesMax: 0,
    allCommandsTemplates: [],
    allCommandsTemplatesFiltered: [],
    terminal_type: [],
    command_type: [],
    protocols: [],
    adminModules: [],
    unit_type: [],
    sensor_type: [],
    validatorSensorTypes: [],
    formula: [],
    gps_category_type: [],
    public_vehicle_type: [],
    sensor_filter_type: [],
    filter: {
      integratorFilterModel: '',
      companyFilterModel: []
    },
    count: 0,
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async postAllCommandsTemplates({ from = 0, count = 100, field, fields, value }, pagination) {
      try {
        if (!this.allCommandsTemplates.length || pagination) {
          const formData = {
            from,
            count,
            field,
            value,
            fields
          };
          if (this.filter.integratorFilterModel) {
            formData.dealer = { id: this.filter.integratorFilterModel };
          }
          if (this.filter.companyFilterModel.length) {
            formData.companies = this.filter.companyFilterModel.map((comp) => ({ id: comp }));
          }
          if (this.sortDesc !== '') {
            formData.sortDesc = this.sortDesc;
            formData.sortField = this.sortField;
          }
          const response = await CommandsTemplates.postAllCommandsTemplates(formData);
          this.allCommandsTemplates = response.list;
          this.allCommandsTemplatesFiltered = response.list;
          this.allCommandsTemplatesMax = response.max;
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async sideFilterCommandsTemplates(formData) {
      try {
        const response = await CommandsTemplates.filterCommandsTemplates(formData);
        this.allCommandsTemplatesFiltered = response.list;
        this.allCommandsTemplatesMax = response.max;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteCommandsTemplates(id) {
      try {
        const response = await CommandsTemplates.deleteCommandsTemplates(id);
        this.allCommandsTemplatesFiltered.forEach((val, key) => {
          if (val.id === id) {
            this.allCommandsTemplatesFiltered.splice(key, 1);
            this.allCommandsTemplatesMax -= 1;
          }
        });
      } catch (e) {
        throw new Error(e);
      }
    },

    async updateCommandsTemplates(data) {
      try {
        const response = await CommandsTemplates.updateCommandsTemplates(data);
        this.command_type.map((val, key) => {
          if (response.name === val.name) {
            this.command_type.splice(key, 1, response);
          }
        });
        this.allCommandsTemplatesFiltered.map((val, key) => {
          if (response.id === val.id) {
            this.allCommandsTemplatesFiltered.splice(key, 1, response);
          }
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async createCommandTemplate(data) {
      try {
        const response = await CommandsTemplates.createCommandTemplate(data);
        this.allCommandsTemplatesMax += 1;
        this.allCommandsTemplatesFiltered.push(response);
      } catch (e) {
        throw new Error(e);
      }
    },

    async getDictionaries() {
      try {
        if (!this.adminModules.length) {
          const response = await CommandsTemplates.getDictionaries();
          this.terminal_type = response.filter((el) => el.type === 'terminal_type');
          this.command_type = response.filter((el) => el.type === 'command_type');
          this.protocols = response.filter((el) => el.type === 'retranslator_protocols');
          this.adminModules = response.filter((el) => el.type === 'admin_modules');
          this.unit_type = response.filter((el) => el.type === 'unit_type');
          this.sensor_type = response.filter((el) => el.type === 'sensor_type');
          this.validatorSensorTypes = response.filter((el) => el.type === 'validator_sensor_type');
          this.formula = response.filter((el) => el.type === 'formula');
          this.gps_category_type = response.filter((el) => el.type === 'gps_category_type');
          this.public_vehicle_type = response.filter((el) => el.type === 'public_vehicle_type');
          this.sensor_filter_type = response.filter((el) => el.type === 'sensor_filter_type');
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },

    async filterCommandsTemplates(data) {
      try {
        const response = await this.postAllCommandsTemplates(
          {
            from: 0,
            count: 100,
            value: data.value,
            field: data.valueFilter?.value || ''
          }, true);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async clearTemplatesFilter() {
      this.filter.integratorFilterModel = '';
      this.filter.companyFilterModel = [];
      const clearData = {
        companies: [],
        count: 100,
        field: '',
        fields: [],
        from: 0,
        value: '',
        conditions: [],
        model: 'commands_templates'
      };
      try {
        await this.postAllCommandsTemplates(clearData, true);
      } catch (error) {
        console.error('Error during clearing command templates:', error);
      }
    },

    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE_COMMAND_TEMPLATE(id);

      const data = await CommandsTemplates.getCommandTemplate(id);

      operation === 'POST' ? this.ADD_COMMAND_TEMPLATE(data) : this.UPDATE_COMMAND_TEMPLATE(data);
    },
    ADD_COMMAND_TEMPLATE(commandTemplate) {
      if (!this.allCommandsTemplatesFiltered.some((x) => x.id === commandTemplate.id)) {
        this.allCommandsTemplates = this.allCommandsTemplatesFiltered = [...this.allCommandsTemplatesFiltered, commandTemplate];
      }
    },
    UPDATE_COMMAND_TEMPLATE(commandTemplate) {
      const idx = this.allCommandsTemplatesFiltered.findIndex((elem) => elem.id === commandTemplate.id);

      if (idx === -1) return;

      this.allCommandsTemplates = this.allCommandsTemplatesFiltered = [...this.allCommandsTemplatesFiltered.slice(0, idx), commandTemplate, ...this.allCommandsTemplatesFiltered.slice(idx + 1)];
    },
    DELETE_COMMAND_TEMPLATE(id) {
      this.allCommandsTemplates = this.allCommandsTemplatesFiltered = this.allCommandsTemplatesFiltered.filter((item) => item.id !== id);
    },
    updateSortedCommandsTemplates(payload) {
      this.allCommandsTemplatesFiltered = payload.list;
    }
  },

  getters: {
    getAllCommandsTemplates: (state) => state.allCommandsTemplatesFiltered,
    getAdminModules: (state) => state.adminModules,
    unitTypes: (state) => state.unit_type,
    terminalTypes: (state) => state.terminal_type,
    sensorTypes: (state) => state.sensor_type,
    getValidatorSensorTypes: (state) => state.validatorSensorTypes,
    formulaTypes: (state) => state.formula,
    filterTypes: (state) => state.sensor_filter_type,
    gps_category_types: (state) => state.gps_category_type,
    public_vehicle_types: (state) => state.public_vehicle_type
  }
});
