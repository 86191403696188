<template>
  <div v-if="modelValue" :class="['dialog__backdrop', classes]">
    <transition name="slide-fade" appear>
      <div
        v-click-outside="updateModel"
        class="dialog">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, onMounted, defineEmits, onUnmounted } from 'vue';

const props = defineProps({
  modelValue: [String, Number, Boolean],
  width: {
    type: String,
    default: 'max-content'
  },
  start: {
    type: Boolean,
    default: false
  },
  end: {
    type: Boolean,
    default: false
  },
  outside: {
    type: Boolean,
    default: true,
  }
});

const classes = computed(() => {
  return props.start ? 'start' : props.end ? 'end' : '';
});

const emit = defineEmits(['update:modelValue']);

const closeModal = (e) => {
    if (e.keyCode === 27) {
      emit('update:modelValue', false)
    }
};

const updateModel = () => {
  if (props.outside) {
    emit('update:modelValue', false);
  }
}

onMounted(() => {
  window.addEventListener('keyup', closeModal);
})

onUnmounted(() => {
  window.removeEventListener('keyup', closeModal);
})
</script>

<style scoped lang="scss">
.dialog {
  position: relative;
  overflow-y: auto;
  width: v-bind(width);
  height: 100%;
  max-height: 90vh;
  border-radius: 12px;
  transition: 0.3s;
  box-sizing: border-box;

  &__backdrop {
    position: fixed;
    inset: 0;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.start {
  justify-content: flex-start;

  .dialog {
    max-height: 100%;
  }
}

.end {
  justify-content: flex-end;

  .dialog {
    max-height: 100%;
  }
}

.dialog::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dialog {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
